<template>
  <div id='app'>
    <div @dragover.prevent @drop="dragFinish(-1, $event)" v-if="dragging > -1" class="trash-drop"
         v-bind:class="{drag: isDragging}">Удалить
    </div>
    <div v-else>
      <input placeholder="Введите ссылку на сайт партнёра и нажмите Enter" type="text" class="new-todo" v-model="newItem"
             @keyup.enter="addItem">
    </div>
    <div class="todo-list">
      <div class="todo-item" v-for="(item, i) in previewItem" :key="i" draggable="true"
           @dragstart="dragStart(i, $event)"
           @dragover.prevent @dragenter="dragEnter" @dragleave="dragLeave" @dragend="dragEnd"
           @drop="dragFinish(i, $event)"
      >
        <custom-input-file
          @inputFile="inputFile"
          @deleteFile="removeItem"
          :labelPlaceholder="item.link"
          :uploadedFile="item.url"
          :id="item.id"/>
        <input type="text"
               :placeholder="item.link"
               v-model="item.link"
               @focus="focusFileName(item.link)"
               @blur="changeFileName(item.link, item.id)">
      </div>
    </div>
  </div>
</template>

<script>
import CustomInputFile from '@/components/Admin/CustomInputFile'

export default {
  name: 'dragAndDrop',
  components: {
    CustomInputFile
  },
  data () {
    return {
      previewItem: this.$store.getters.GET_MAIN_ITEM_FORUM.partners,
      newItem: '',
      dragging: -1,
      speakerName: ''
    }
  },
  computed: {
    isDragging () {
      return this.dragging > -1
    },
    getPartners () {
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  },
  watch: {
    // '$store.getters.GET_MAIN_ITEM_FORUM' () {
    //   const dataFromServer = this.$store.getters.GET_MAIN_ITEM_FORUM
    //   this.previewItem = dataFromServer.partners // .map(item => item)
    // },
    getPartners () {
      this.setPreview()
    }
  },
  methods: {
    async setPreview () {
      const dataFromServer = await this.$store.getters.GET_MAIN_ITEM_FORUM
      this.previewItem = [...dataFromServer.partners]
    },
    inputFile (event) {
      const currentPartners = this.$store.getters.GET_MAIN_ITEM_FORUM
      // console.log(currentPartners)
      const formData = new FormData()
      formData.append('file', event.file)
      formData.append('link', event.link)
      if (currentPartners.partners.filter(item => { return item.id === event.id }).length !== 0) {
        this.$store.dispatch('updatePartners', { id: event.id, data: formData })
      } else {
        this.$store.dispatch('createForumPartners', { id: this.$route.params.id, data: formData })
      }
      setTimeout(() => this.$store.dispatch('getMainItemForum', this.$route.params.id), 1200)
    },
    addItem () {
      if (!this.newItem || this.newItem === ' ') {
        return
      }
      this.previewItem.push({
        id: this.previewItem.length + 1,
        url: '',
        link: this.newItem
      })
      this.newItem = ''
    },
    removeItem (item) {
      console.error('removeItem')
      // if (this.previewItem.length !== 1) {
      //   this.previewItem.splice(this.previewItem.indexOf(item), 1)
      //   this.$store.dispatch('deletePartnersForum', item)
      // }
      this.previewItem.splice(this.previewItem.indexOf(item), 1)
      this.$store.dispatch('deletePartnersForum', item)
      setTimeout(() => this.$store.dispatch('getMainItemForum', this.$route.params.id), 300)
    },
    removeItemAt (index) {
      console.error('removeItemAt')

      this.$store.dispatch('deletePartnersForum', this.previewItem[index].id)
      this.previewItem.splice(index, 1)
      // console.log(index)
    },
    dragStart (which, ev) {
      // console.log('dragStart', which)

      ev.dataTransfer.setData('Text', this.id)
      ev.dataTransfer.dropEffect = 'move'
      this.dragging = which
    },
    dragEnter (ev) {
      // console.log('dragEnter', ev)
      // if (ev.clientY > ev.target.height / 2) {
      //   ev.target.style.marginBottom = '10px'
      // } else {
      //   ev.target.style.marginTop = '10px'
      // }
    },
    dragLeave (ev) {
      // console.log('dragLeave', ev)
      // ev.target.style.marginTop = '2px'
      // ev.target.style.marginBottom = '2px'
    },
    dragEnd (ev) {
      // console.log('dragEnd', this.previewItem)
      this.dragging = -1
    },
    async dragFinish (to, ev) {
      this.moveItem(this.dragging, to)
      await this.$forceUpdate()
      this.updateSorting()
    },
    moveItem (from, to) {
      if (to === -1) {
        this.removeItemAt(from)
      } else {
        this.previewItem.splice(to, 0, this.previewItem.splice(from, 1)[0])
      }
    },

    async updateSorting () {
      try {
        const bodyData = JSON.stringify({ data_sort: this.previewItem.map((el, index) => ({ id: el.id, sort: index + 1 })) })
        await fetch(`${this.$store.getters.getUrlApi}api/admin/global-forums/partners/${this.$route.params?.id}/update-sort`, {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem('token'),
            'Content-Type': 'application/json'
            // 'Accept': 'application/json'
          },
          body: bodyData
        })
      } catch (e) {

      }
    },

    focusFileName (item) {
      this.speakerName = item
    },
    changeFileName (item, id) {
      if (item !== this.speakerName) {
        const formData = new FormData()
        formData.append('link', item)
        this.$store.dispatch('updatePartners', { id: id, data: formData })
        setTimeout(() => this.$store.dispatch('getMainItemForum', this.$route.params.id), 1200)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .todo-list {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .done {
    color: #888;
  }

  .new-todo {
    width: 100%;
  }

  .trash-drop {
    border: 1px dashed #ccc !important;
    text-align: center;
    color: #e33;
  }

  .trash-drop:-moz-drag-over {
    border: 2px solid red;
  }

  .todo-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26rem;
    margin: .5rem;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: .5rem;
    background-color: #fff;
    box-shadow: 1px 2px 2px #ccc;
    font-size: 1rem;
  }
  .new-todo, .trash-drop {
    width: 55rem;
    margin: .5rem;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: .5rem;
    background-color: #fff;
    box-shadow: 1px 2px 2px #ccc;
    font-size: 1rem;
  }

</style>
