<template>
  <div>
    <router-link class="come-back" to="/admin/forum/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование форума №${$route.params.id}` : 'Создание форума' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createForum" ref="createForum" @keypress.enter.prevent>
      <div class="inputs__container">
        <div class="content-sb">
          <p>Название форума<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="forumData.title" type="text" name="title"
                 class="input blue">
        </div>
        <div class="content-sb">
          <p>Год проведения форума<span>*</span></p>
          <input required placeholder="2020" v-model="forumData.year" type="text" name="year" class="input blue">
        </div>
        <div class="content">
          <p>O форуме<span>*</span></p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="forumData.about_text"></ckeditor>
          <!--          <input required placeholder="Введите описание" v-model="forumData.about_text" type="text" name="about_text" class="input blue">-->
        </div>

        <div v-if="isEdit" class="content">
          <p>Файл</p>
          <custom-input-file
            @inputFile="inputFile"
            @deleteFile="removeItem"
            :uploadedFile="forumData.about_file_url"
            :labelPlaceholder="forumData.about_file_title"
            :id=docId />
        </div>
        <div class="content">
          <p>Исследование<span>*</span></p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="forumData.study_text"></ckeditor>
          <!--          <input required placeholder="Введите описание" v-model="forumData.study_text" type="text" name="study_text" class="input blue">-->
        </div>
        <div class="content-sb">
          <p>Исследование. Ссылка<span>*</span></p>
          <input required placeholder="Введите описание" v-model="forumData.study_link" type="text" name="study_link"
                 class="input blue">
        </div>
        <div class="content-sb">
          <p>Контактный телефон<span>*</span></p>
          <input required placeholder="Введите номер" v-model="forumData.contacts_phone" type="text"
                 name="contacts_phone" class="input blue">
        </div>
        <div class="content-sb">
          <p>Электронная почта<span>*</span></p>
          <input required placeholder="Введите e-mail" v-model="forumData.contacts_emails" type="text"
                 name="contacts_emails" class="input blue">
        </div>
        <!--        <div class="content-sb">-->
        <!--          <p>Дата<span>*</span></p>-->
        <!--          <input required placeholder="Введите дату" v-model="forumData.date_time" type="datetime-local" name="date_time" class="input blue">-->
        <!--        </div>-->
        <div v-if="isEdit" class="content">
          <p>Партнёры<span>*</span></p>
          <drag-and-drop/>
        </div>

      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/forum" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomInputFile from '@/components/Admin/CustomInputFile'
import DragAndDrop from '@/components/Admin/DragAndDrop'

export default {
  name: 'createForum',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      docId: 999,
      forumData: {
        title: 'Форум',
        about_text: null,
        study_text: null,
        year: 2020,
        study_link: '#',
        about_file: null,
        contacts_phone: '8 800 707 67 42',
        contacts_emails: 'forum@oprf.ru',
        about_file_title: '',
        about_file_url: ''
        // file: '', // партнёры
        // link: '' // партнёры заголовок
      },
      partnerData: {
        file: '', // партнёры
        link: '' // партнёры заголовок
      },
      partnersBlock: [],
      contentBlocks: [],
      currentContentBlockType: 0
    }
  },
  components: {
    CustomInputFile,
    TricolorLine,
    DragAndDrop
  },
  created () {
    if (this.isEdit) {
      this.$store.dispatch('getMainItemForum', this.$route.params.id)
    }
  },
  computed: {
    getCurrentItemForum () {
      console.log('computed')
      return this.$store.getters.GET_MAIN_ITEM_FORUM
    }
  },
  watch: {
    getCurrentItemForum () {
      this.getCurrForumData()
    }
  },
  methods: {
    async getCurrForumData () {
      const dataFromServer = await this.$store.getters.GET_MAIN_ITEM_FORUM
      console.log(this.$store.getters.GET_MAIN_ITEM_FORUM)
      console.log(dataFromServer)
      this.forumData = {
        title: dataFromServer.title,
        about_text: dataFromServer.about_text,
        study_text: dataFromServer.study_text,
        year: dataFromServer.year,
        // date_time: dataFromServer.date_time.split(' ').join('T'),
        study_link: dataFromServer.study_link,
        about_file: dataFromServer.about_file,
        contacts_phone: dataFromServer.contacts_phone,
        contacts_emails: dataFromServer.contacts_emails,
        about_file_title: dataFromServer.about_file_title,
        about_file_url: dataFromServer.about_file_url
        // link: 'link' in dataFromServer.partners[0] ? dataFromServer.partners[0].link : ''
        // system_tags_list: dataFromServer.system_tags_list ? dataFromServer.system_tags_list.join(',') : '',
      }

      this.previewBlob = dataFromServer.partners.map(item => item.url)
      this.partnersBlock = [...this.previewBlob]
      console.log(this.partnersBlock)
      this.$forceUpdate()
    },
    // Добавление одного файла на сервер
    inputFile (event) {
      const formData = new FormData()
      formData.append('about_file', event.file)
      this.$store.dispatch('updateMainForum', { id: this.$route.params.id, data: formData })
    },
    // Удаление файла с сервера
    removeItem (event) {
      console.log(event)
    },
    dragStartHandler (event) {
      console.log(event)
    },
    addPartners () {
      this.previewBlob.push(null)
      console.log(this.previewBlob)
    },
    removeItemPartner (item) {
      console.log(item)
      this.previewBlob.splice(item, 1)
      console.log(this.previewBlob)
      this.$forceUpdate()
    },
    previewFiles (event) {
      // console.log(event)
      console.log(event.target.files)
    },
    createForum () {
      // console.log(this.forumData.title)
      const formData = new FormData()
      for (const key in this.forumData) {
        if (key !== 'id' && key !== 'about_file') {
          formData.append(key, this.forumData[key])
        }
      }
      formData.append('contacts_phone', JSON.stringify(this.forumData.contacts_phone))
      formData.append('contacts_emails', JSON.stringify(this.forumData.contacts_emails))
      if (this.isEdit) {
        this.$store.dispatch('updateMainForum', { data: formData, id: this.$route.params.id })
      } else {
        this.$store.dispatch('createMainForum', formData)
        this.$router.go(-1)
      }
    },

    setContentBlockType (index) {
      this.currentContentBlockType = index
    },
    setBlockContentData (data) {
      if (data.data.image && data.data.image[0]) {
        this.contentBlocks[data.index].blob = null
      }
      Object.entries(data.data).forEach(([key, value]) => {
        this.contentBlocks[data.index][key] = value
      })
    },

    setFile (file, key) {
      console.log({ file, key })
      // this.forumData.file = file
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        // this.previewBlob = null
        this[key[0]][key[1]] = file
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb, .content {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }

  }

  .content-partners {
    display: flex;
    margin: 1rem;
    flex-wrap: wrap;
  }

  .content-partner {
    display: flex;
    flex-direction: column;
    margin: .5rem;

    > .input {
      margin: .5rem auto;
      width: 12.18rem;
    }

    &__remove {
      background: red;
      border: 1px solid #f00;
      border-radius: 50%;
      color: white;
      font-size: 2rem;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 0.5;
      margin: 0 0 -2.1rem 10rem;
      padding: 0;
      text-align: center;
      cursor: pointer;
      z-index: 1000;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 5px;
          background-color: #EEF4FA;
        }

        &::-webkit-scrollbar-track {
          /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
        }

        &::-webkit-scrollbar-thumb {
          background-color: #246CB7;
          border-radius: 2.5px;
        }
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
